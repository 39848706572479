.recent-pages {
    &__slider {
        margin-top: 16px;
        overflow: visible;
    }

    .slider-wrapper__title {
        flex-basis: 50%;
    }

    .keen-slider__slide {
        min-height: unset;
    }

    .card__link-card {
        margin-bottom: 0;

        .card__body {
            display: flex;
            justify-content: center;
        }

        .card__title {
            // @include header(4);
            padding-left: 8px;
            white-space: nowrap;
            overflow: hidden;
                
            &:after {
                content: '';
                top: 0;
                left: 0;
                position: absolute;
                // background: linear-gradient(90deg, rgba(0, 0, 0, 0) 70%, #ffffff 88%);
                width: 100%;
                height: 100%;
                background: -moz-linear-gradient(left,  rgba(0,0,0,0) 70%, #ffffff 88%);
                background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 70%,#ffffff 88%);
                background: linear-gradient(to right,  rgba(0,0,0,0) 70%,#ffffff 88%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#e0ffffff',GradientType=1 );

            }
        }
        .card__arrow {
            @include header(6);
            display: flex;
            align-items: center;
            color: colors("secondary");
            padding-left: 8px;
            padding-right: 8px;
            height: 100%;
            z-index: 0;
        }
    }
}