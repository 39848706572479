@mixin box-shadow-primary {
    box-shadow: 0px 4px 16px rgba(3, 75, 78, 0.04), 0px 8px 24px rgba(3, 75, 78, 0.08);
}

@mixin box-shadow-hover {
    box-shadow: 0px 4px 16px rgba(3, 75, 78, 0.04), 0px 8px 32px rgba(3, 75, 78, 0.12);
}

@mixin box-shadow-primary-large {
    box-shadow: 0px 4px 16px rgba(3, 75, 78, 0.04), 0px 24px 56px rgba(3, 75, 78, 0.08);
}

@mixin box-shadow-hover-large {
    box-shadow: 0px 4px 16px rgba(3, 75, 78, 0.04), 0px 24px 64px rgba(3, 75, 78, 0.12);
}
