.employees-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
        padding: 0 42px;
    }

    &--right {
        margin-top: 64px;
    }

    &__employee-item {
        width: 100%;
        margin-top: 40px;
        &:first-child {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 104px;
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .employee {
        &__item-content {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
        
        &__details {
            margin-top: 24px;
            align-self: flex-start;

            * {
                color: white;
            }

            @include media-max-width(sm-large) {
                margin-top: 16px;
            }
        }

        &__title {
            margin-bottom: 8px;
            @include media-max-width(sm-large) {
                margin-bottom: 0;
            }
        }

        &__name {
            font-weight: fontWeight(bold);
            margin: 0;
            margin-bottom: 4px;
        }

        &__contact-links {
            display: flex;
            flex-wrap: wrap;
            font-size: fontSize(medium);
            line-height: 26px;

            a {
                border-bottom: 2px solid colors("primary");
                font-weight: fontWeight("normal");

                &:first-child {
                    margin-right: 24px;
                }
            }

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: flex-start;

                a {
                    margin-top: 8px;
                    margin-right: 0;
                }
            }
        }

        &__image {
            max-width: 100%;
            max-height: 544px;
        }
    }
}