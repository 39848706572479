.social-media-bubbles-list {
    display: flex;

    // justify-content: space-between;
    a.social-media-bubble {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }

    @include media-breakpoint-down(sm-large) {
        flex-direction: column;
        align-items: flex-end;

        a.social-media-bubble {
            margin-right: 0;
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.social-media-bubble {
    display: inline-block;
    border-radius: 100px;
    padding: 16px;
    background-color: colors("primary", 200);
    color: colors("primary", 600) !important;

    @include ease-in-out(0.2s);

    &:hover {
        background: colors("primary", 300);
    }

    &--small {
        padding: 10px;
    }
}