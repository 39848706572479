.cta-fullwidth {
    display: flex;
    justify-content: space-evenly;
    text-align: center;

    // padding-top: 96px;
    // padding-bottom: 84px;
    // @include media-breakpoint-down(md) {
    //     padding-top: 54px;
    //     padding-bottom: 24px;
    // }

    // Expand outside row
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
    
    &__content {
        max-width: 700px;
        padding: 96px 16px 62px;

        @include media-breakpoint-down(md) {
            padding-top: 54px;
            padding-bottom: 22px;
        }
    }

    &__title {
        margin-bottom: 16px;

        @include media-breakpoint-down(md) {
            margin-bottom: 8px;
        }
    }
    
    &__cta-button {
        margin-top: 32px;

        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
    }
}