/* Bootstrap override */
$grid-breakpoints: (
    xs: 0,
    sm: 360px,
    sm-large: 580px,
    md: 736px,
    md-large: 1000px,
    lg: 1312px,
    xl: 1600px
);

$grid-gutter-width: 32px;

$container-max-widths: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 1312px,
  xl: 1600px,
);