@font-face {
  font-family: 'fontello';
  src: url('/fonts/fontello.eot?77151702');
  src: url('/fonts/fontello.eot?77151702#iefix') format('embedded-opentype'),
       url('/fonts/fontello.woff2?77151702') format('woff2'),
       url('/fonts/fontello.woff?77151702') format('woff'),
       url('/fonts/fontello.ttf?77151702') format('truetype'),
       url('/fonts/fontello.svg?77151702#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/fonts/fontello.svg?77151702#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-phone:before { content: '\e800'; } /* '' */
.icon-menu:before { content: '\e801'; } /* '' */
.icon-arrow_left:before { content: '\e802'; } /* '' */
.icon-arrow_right:before { content: '\e803'; } /* '' */
.icon-check_circle:before { content: '\e804'; } /* '' */
.icon-email:before { content: '\e805'; } /* '' */
.icon-check:before { content: '\e806'; } /* '' */
.icon-emoji_emotions:before { content: '\e807'; } /* '' */
.icon-location:before { content: '\e808'; } /* '' */
.icon-chevron_left:before { content: '\e809'; } /* '' */
.icon-chevron_right:before { content: '\e80a'; } /* '' */
.icon-chevron_up:before { content: '\e80b'; } /* '' */
.icon-chevron_down:before { content: '\e80c'; } /* '' */
.icon-play_circle:before { content: '\e80d'; } /* '' */
.icon-cloud:before { content: '\e80e'; } /* '' */
.icon-globe:before { content: '\e80f'; } /* '' */
.icon-star:before { content: '\e810'; } /* '' */
.icon-add_to_cart:before { content: '\e811'; } /* '' */
.icon-build:before { content: '\e812'; } /* '' */
.icon-device_hub:before { content: '\e813'; } /* '' */
.icon-verified:before { content: '\e814'; } /* '' */
.icon-fullscreen:before { content: '\e815'; } /* '' */
.icon-zoom_in:before { content: '\e816'; } /* '' */
.icon-zoom_out:before { content: '\e817'; } /* '' */
.icon-add:before { content: '\e818'; } /* '' */
.icon-remove:before { content: '\e819'; } /* '' */
.icon-backup:before { content: '\e81a'; } /* '' */
.icon-warning:before { content: '\e81b'; } /* '' */
.icon-close:before { content: '\e81c'; } /* '' */
.icon-warning-filled:before { content: '\e81d'; } /* '' */
.icon-business_center:before { content: '\e81e'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-cloud-upload:before { content: '\f0ee'; } /* '' */
.icon-youtube:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */

[class^="motillo-icon-"]:before, [class*=" motillo-icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased motillo-icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.motillo-icon-phone:before { content: '\e800'; } /* '' */
.motillo-icon-menu:before { content: '\e801'; } /* '' */
.motillo-icon-arrow_left:before { content: '\e802'; } /* '' */
.motillo-icon-arrow_right:before { content: '\e803'; } /* '' */
.motillo-icon-check_circle:before { content: '\e804'; } /* '' */
.motillo-icon-email:before { content: '\e805'; } /* '' */
.motillo-icon-check:before { content: '\e806'; } /* '' */
.motillo-icon-emoji_emotions:before { content: '\e807'; } /* '' */
.motillo-icon-location:before { content: '\e808'; } /* '' */
.motillo-icon-chevron_left:before { content: '\e809'; } /* '' */
.motillo-icon-chevron_right:before { content: '\e80a'; } /* '' */
.motillo-icon-chevron_up:before { content: '\e80b'; } /* '' */
.motillo-icon-chevron_down:before { content: '\e80c'; } /* '' */
.motillo-icon-play_circle:before { content: '\e80d'; } /* '' */
.motillo-icon-cloud:before { content: '\e80e'; } /* '' */
.motillo-icon-globe:before { content: '\e80f'; } /* '' */
.motillo-icon-star:before { content: '\e810'; } /* '' */
.motillo-icon-add_to_cart:before { content: '\e811'; } /* '' */
.motillo-icon-build:before { content: '\e812'; } /* '' */
.motillo-icon-device_hub:before { content: '\e813'; } /* '' */
.motillo-icon-verified:before { content: '\e814'; } /* '' */
.motillo-icon-fullscreen:before { content: '\e815'; } /* '' */
.motillo-icon-zoom_in:before { content: '\e816'; } /* '' */
.motillo-icon-zoom_out:before { content: '\e817'; } /* '' */
.motillo-icon-add:before { content: '\e818'; } /* '' */
.motillo-icon-remove:before { content: '\e819'; } /* '' */
.motillo-icon-backup:before { content: '\e81a'; } /* '' */
.motillo-icon-warning:before { content: '\e81b'; } /* '' */
.motillo-icon-close:before { content: '\e81c'; } /* '' */
.motillo-icon-warning-filled:before { content: '\e81d'; } /* '' */
.motillo-icon-business_center:before { content: '\e81e'; } /* '' */
.motillo-icon-facebook:before { content: '\f09a'; } /* '' */
.motillo-icon-linkedin:before { content: '\f0e1'; } /* '' */
.motillo-icon-cloud-upload:before { content: '\f0ee'; } /* '' */
.motillo-icon-youtube:before { content: '\f16a'; } /* '' */
.motillo-icon-instagram:before { content: '\f16d'; } /* '' */