$colors: (
  primary: (
    50: #F7FCFC,
    100: #E6F4F5,
    200: #B4DFE1,
    300: #83CACD,
    400: #51B5B9,
    450: #1FA0A5,
    500: #06959B,
    550: #05868C,
    600: #04686D,
    700: #034B4E,
    800: #022D2E,
    900: #010F0F
  ),
  secondary: (
    100: #FFFAEC,
    200: #FFF0C7,
    300: #FFE7A2,
    400: #FFDD7C,
    450: #FFD357,
    500: #FFCE44,
    550: #E6B93D,
    600: #B39030,
    700: #806722,
    800: #4C3E14,
    900: #191507
  ),
  success: (
    200: #AEDCBE,
    450: #75C18D,
    500: #4CA65D,
    550: #347441,
    600: #1C4425
  ),
  warning: #F5BE49,
  danger: #CF2F44,
  danger-light: #E8A5B3,
  black: #000000,
  grey-darker: #5A5D6D,
  grey-dark: #8F9494,
  grey: #B3B9B9,
  grey-light: #D9DCDC,
  grey-lighter: #F2F4F4,
);

@function colors($color, $level: 500) {
    $colorMap: map-get($colors, $color);
    @if type-of($colorMap) == "map" {
      @return map-get($colorMap, $level);
    }@else{
      @return $colorMap
    }
}


$hamburger-layer-color: colors("primary");
$hamburger-active-layer-color: white;
$hamburger-layer-spacing: 4px;
$hamburger-layer-width: 24px;
$hamburger-layer-border-radius: 1px;