@mixin ease-in-color-shadow($seconds) {
    transition: color $seconds ease-in-out, background-color $seconds ease-in-out, border-color $seconds ease-in-out, box-shadow $seconds ease-in-out;
}

@mixin ease-in-out($seconds, $attribute: 0) {
    @if $attribute != 0 {
        transition: $attribute $seconds ease-in-out;
    }@else{
        transition: $seconds ease-in-out;
    }
}