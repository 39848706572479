.logo-carousel {
    display: flex;
    justify-content: space-between;
 
    &--keen-slider {
        overflow: hidden;
        padding: 40px; 
        @include media-breakpoint-down(sm) {
            padding: 20px;
        }
        
        .logo-carousel--barrier {
            display: flex;
            &::before, &::after {
                top: 0;
                box-sizing: border-box;
                content: "";
                height: 100%;
                position: absolute;
                width: 15%;
                z-index: 1;
            }
            @include media-breakpoint-down(md) {
                &::before, &::after {
                    width: 10%;
                }
            }
            .keen-slider__slide {
                display: flex;
                justify-content: center;
                flex: 1;
            }
            .logo-carousel--logo {
                img {
                   max-height: 80px;
                   max-width: 160px;
            
                    @include media-breakpoint-down(md) {
                        max-width: 100px;
                    }
            
                    @include media-breakpoint-down(sm) {
                        max-width: 70px;
                    }
                }
                a {
                   text-decoration: none;
                   color: inherit;
                }
            }
        }
    }
 }