.logo-slider {
    overflow: auto;
    width: 100%;

    .keen-slider__slide {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 144px;
            max-height: 44px;
            object-fit: cover;
        }
    }
}