.employee-page {
    padding-top: 56px;
    padding-bottom: 82px;

    @include media-breakpoint-down(md) {
        padding-top: 8px;
    }

    &__name {
        margin: 0;
    }

    &__title {
        margin-top: 8px;
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-height: 700px;
            max-width: 100%;
        }
    }

    &__details {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            padding-left: 64px;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 128px;
        }

        @include media-breakpoint-down(sm-large) {
            margin-top: 16px;
        }
    }

    &__contact-links {
        list-style-type: none;
        padding: 0;

        a {
            display: inline-block;
            border-bottom: 2px solid colors("primary");
            margin-right: 24px;
            font-weight: fontWeight(bold);
            margin-top: 16px;
        }
    }

    .social-media-bubbles-list {
        margin-top: 24px;

        .social-media-bubble,
        .social-media-bubble i {
            color: colors("primary");
        }
    }
}

body.dark-theme .employee-page {
    * {
        color: colors("white")
    }
}