.alert {
    display: flex;
    align-items: center;
    padding: 8px;

    @include text-smallest;

    &--large {
        padding: 16px;
        @include text(medium);
    }

    @each $colorName, $colorValue in $colors {
        &--#{$colorName} {
            // Use "colors()" function to get the color, some colors have multiple tones
            background-color: rgba(colors($colorName), 0.5);
            color: colors("black");
        }
    }
}