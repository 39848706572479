.media-block-image {
    width: 100%;

    &__caption {
        color: #B3B9B9;
        @include text-smallest;
    }
}

.media-block-image[data-expandable="true"] {
    cursor: pointer;
}