.btn {
    @include btn;

    @each $enabledButtonType in $enabled-buttons {
        &--#{$enabledButtonType} {
            @include btn-style($enabledButtonType);

            a.btn:visited {
                color: button-font-color($enabledButtonType);
            }

            a {
                display: block;
                font-size: map-get($button-font-sizes, $enabledButtonType);
                color: button-font-color($enabledButtonType) !important;
                background: none;
                width: 100%;
            }
        }
    }

    &--large {
        @include btn-size("large");
    }

    &--small {
        @include btn-size("small");
    }

    &--text,
    &--text:hover,
    &--text:active,
    &--text:focus {
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
    }
}

a.btn {
    display: inline-block;
    text-decoration: none;

    @each $enabledButtonType in $enabled-buttons {
        &--#{$enabledButtonType}:visited {
            color: button-font-color($enabledButtonType);
        }
    }
}