$row-padding-default:           104px;
$row-padding-default-mobile:    48px;
$row-width-default:             100%;

$row-paddings: (
    default: $row-padding-default,
    large: 158px,
    medium: 48px,
    small: 32px,
    xsmall: 16px
);

$row-paddings-mobile: (
    default: $row-padding-default-mobile,
    large: 96px,
    medium: 32px,
    small: 16px,
    xsmall: 8px,
);

$row-widths: (
    default: $row-width-default,
    body-text: 700px,
    body-text-large: 864px,
);

$cell-paddings: (
    default: (
        x: 48px,
        y: 18px
    ),
    small: (
        x: 32px,
        y: 12px
    )
);

$cell-paddings-mobile: (
    default: (
        x: 0,
        y: 0
    ),
    small: (
        x: 0,
        y: 0
    )
);