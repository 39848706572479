.list-view {
    display: flex;
    flex-wrap: wrap;

    &--dark &__item {
        color: colors("primary", 800);
        &__title,
        &__description {
            color: colors("primary", 800);
        }
    }

    &--light &__item {
        color: white;
        &__title,
        &__description  {
            color: white;
        }
    }

    &__item {
        display: flex;

        &__body {
            word-break: break-word;
        }

        &__title {
            margin-top: 0;
            margin-bottom: 0;
        }

        &__description {
            margin-top: 8px;

            @include text(default);
            color: colors("primary", 800);

            // Remove margin top of first markdown paragraph
            & > p:first-child {
                margin-top: 0;
            }
            
            p {
                margin-bottom: 0;
            }
        }

        &__image {
            &--small {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: colors("grey-lighter");
                border-radius: 4px;
                padding: 12px;
                min-width: 72px;
                width: 72px;
                height: 72px;
                margin-bottom: 24px;
            }

            &--icon {
                background-color: white;
                border: 1px solid colors("primary", 100);
            }
    
            img {
                display: block;
                width: 100%;
            }

            i {
                &::before {
                    color: colors("primary");
                    font-size: 34px;
                }
            }
        }

        &--image-left &__image {
            margin-right: 24px;
            margin-bottom: 0;
        }

        &--image-right &__image {
            margin-left: 24px;
            margin-bottom: 0;
        }
    }

    &--same-height &__item {
        display: flex;
        flex-direction: column;

        // Three cards on desktop
        flex: 1 calc(33% - 32px);

        @include media-breakpoint-up(md-large) {
            // Each 3rd item
            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        @include media-breakpoint-down(md) {
            // Show two cards on smaller screens
            flex: 1 calc(50% - 32px);

            // Each other, starting with second item
            &:nth-child(2n+2) {
                margin-right: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            // Take whole width on mobile
            flex: 1 100%;
            margin-right: 0;
        }

        margin-right: 32px;
        
        &:last-child {
            margin-right: 0;
        }
    }

    &__no-results-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 32vh;

        .no-results__title {
            color: colors("primary", 500);
            text-align: center;
        }

    }
}

.filter-bar {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 16px;
        row-gap: 12px;
        margin: 0 auto 32px auto;
        max-width: 640px;
        flex-wrap: wrap;

        // Hiding scrollbar when overflowing
        -ms-overflow-style: none; 
        scrollbar-width: none;  
        &::-webkit-scrollbar {
            display: none;
        }

        .btn {
            white-space: nowrap;
            min-width: fit-content;
        }

        @include media-breakpoint-down(md) {
            flex-wrap: nowrap;
            overflow-x: auto;
            justify-content: flex-start;
            margin-left: -16px;
            margin-right: -16px;
            padding: 0 16px;
            max-width: none;
        }
    }

    &__container-effect {
        position: relative;

        &::before, 
        &::after {
            content: "";
            pointer-events: none;
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 16px; 

            @include media-breakpoint-down(md){
                opacity: 1;
            }
        }
    }

    &__clear-btn {

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }

        &-desktop {
            background: none;
            border: 2px solid #B3B9B9;
            color: #B3B9B9;
            font-size: 28px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 99px;
            min-width: none;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            
            @include media-breakpoint-down(md){
                display: none;
            }

            &:hover {
                border-color: colors("grey-darker");
                color: colors("grey-darker");
            }
        }

        &-mobile {
            display: none;
            margin-bottom: 12px;
            background: none;
            font-size: fontSize("medium");
            color: colors("primary", 500);
            font-weight: fontWeight("bold");
            border: none;
            padding: 0;
            text-decoration: underline;

            @include media-breakpoint-down(md){
                display: block;
            }
        }
    }
}