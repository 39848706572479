// Headers
@mixin header($size) {
    font-weight: header-font-weight($size);
    font-size: header-font-size($size);
    line-height: header-line-height($size);
    @include media-breakpoint-down(md) {
        font-size: header-font-size($size, true);
        line-height: header-line-height($size, true);
    }
    color: colors("primary", 800);
}

// Body
@mixin text($size) {
    line-height: $text-line-height;
    font-size: map-get($text-font-sizes, $size);
}

@mixin text-small {
    font-size: fontSize(small);
    line-height: 19px;
    font-weight: fontWeight(default);
}

@mixin text-smallest {
    font-size: fontSize(smallest);
    line-height: 19px;
    font-weight: fontWeight(default);
}

@mixin rich-text-body {
    * {
        color: colors("primary", 800);
    }

    .btn--outline {
        color: button-font-color("outline");
    }

    .btn-white {
        color: button-font-color("white")
    }

    a,
    a:visited {
        text-decoration: none;
        color: colors("primary");
        font-weight: fontWeight("bold");
    }

    ul {
        margin: 0;
    }

    p {
        margin-top: 24px;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            margin-top: 16px;
        }

        & + ol,
        & + ul {
            margin-top: 16px;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 40px;
        margin-bottom: 0;

        & + * {
            margin-top: 16px;
        }

        & + ul,
        & + ol {
            margin-top: 24px;
        }

        & + .ingress {
            margin-top: 32px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 32px;

            & + ul,
            & + ol {
                margin-top: 16px;
            }

            & + .ingress {
                margin-top: 16px;
            }
        }
    }

    ul, ol { 
        @include text("default");
    }

    & > *:first-child {
        margin-top: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    img {
        width: unset;
        height: unset;
        max-width: 100%;
    }

    .rich-text__img--fullwidth {
        width: 100%;
        height: auto;
    }
    
    p.rich-text__img-caption {
        @include text-smallest;
        color: #B3B9B9;
        margin-top: 0;
    }
}