footer {
    padding: 72px 0;

    img.footer-logo {
        display: block;
        max-width: 140px;
        width: 100%;
    }

    .footer-menu {
        @include media-breakpoint-down(sm-large) {
            margin-top: 32px;
            margin-bottom: 32px;
        }

        a {
            display: block;
            @include text(default);
            color: black;
            font-weight: fontWeight("bold");
            margin-bottom: 16px;
        }
    }

    .footer-contact-links a {
        display: block;
        @include text(default);
        font-weight: fontWeight("bold");

        &:first-child {
            margin-bottom: 12px;

            @include media-breakpoint-down(md) {
                margin-bottom: 6px;
            }
        }

        i {
            color: colors("primary", 600);
            margin-right: 6px;
        }
    }

    .social-media-bubbles-list {
        position: absolute;
        bottom: 0;

        @include media-breakpoint-only(sm-large) {
            left: 0;
        }

        @include media-breakpoint-down(sm) {
            right: 16px;
        }
    }

    .footer-contact-links {
        @include media-breakpoint-down(sm-large) {
            margin-top: 32px;
        }
    }
}

body.dark-theme footer {
    .footer-address-field,
    .footer-address-field * {
        color: colors("primary", 800);
    }
}