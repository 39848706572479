.partner-info-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: colors("white");
    padding: 48px;

    @include box-shadow-primary-large;

    @include media-breakpoint-down(sm-large) {
        padding: 32px 16px;
        margin-left: -$grid-gutter-width/2;
        margin-right: -$grid-gutter-width/2;
    }

    &--no-header {
        padding-top: 16px;
    }

    &--no-footer {
        padding-bottom: 16px;
    }

    &__header {
        margin-bottom: 32px;
    }

    &__title,
    &__caption,
    &__footer-caption {
        margin: 0;
        color: colors("primary", 800) !important;
    }

    &__caption {
        margin-top: 16px;
        @include text("default");
    }

    &__footer-caption {
        @include text("default");
    }

    &__footer {
        margin-top: 32px;
    }

    &__columns {
        display: flex;
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        h5 {
            margin: 0;
            margin-bottom: 16px;
            color: colors("primary", 800) !important;
            min-width: 52px;
            width: 100%;
            text-align: center;
            padding-right: 16px;
        }

        &:first-child {
            align-items: flex-start;
            flex-grow: 1;

            h5 {
                text-align: start;
                width: unset;
            }
        }

        &:last-child {
            h5 {
                padding-right: 0;
            }
        }
    }

    &__column:first-child &__row {
        justify-content: flex-start;
        width: 100%;
        padding: 16px;
    }

    &__row {
        display: flex;
        width: 100%;
        padding: 16px;
        justify-content: center;
        height: 58px;
        align-items: center;

        background-color: colors("primary", 100);
        &:nth-child(2n+1) {
            background-color: colors("white");
        }
    }

    &__row-data {
        display: flex;
        justify-content: flex-end;
        flex: 1;

        &:first-child {
            justify-content: flex-start;
        }
    }

    &__row-text {
        @include text(medium);
        margin: 0;
    }

    &__icon {
        width: 20px;
        height: 20px;
        object-fit: scale-down;
    }
}