.background-effect {
    overflow: hidden;
    position: relative;

    &--curve-bottom {
        margin-bottom: 50px;
        position: absolute;
        background-color: inherit;
        border-radius: 100%;
        width: 120%;
        height: 210px;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-down(lg) {
            height: 160px;
        }

        @include media-breakpoint-down(md) {
            height: 120px;
        }

        @include media-breakpoint-down(sm) {
            height: 100px;
        }
    }

    &--curve-top {
        position: absolute;
        background: white;
        border-radius: 100%;
        content: "";
        width: 120%;
        height: 210px;
        top: -75px;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-up(lg) {
            height: 340px;
            top: -210px;
        }

        @include media-breakpoint-down(lg) {
            height: 170px;
            top: -67px;
        }

        @include media-breakpoint-down(sm) {
            height: 150px;
            top: -55px;
        }
    }
}

body.dark-theme .background-effect--curve-top {
    background-color: colors("primary", 800);
}