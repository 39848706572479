.rich-text-container {
    // img:not([width]) {
    //     width: 100%;
    // }

    [class^="col-"],
    .container,
    .row {
        position: unset;
        display: block;
        flex-wrap: unset;
        flex: unset;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

ul.rich-text--checkmarks {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

ul.rich-text--checkmarks li {
    background: url(/images/checkmark-light-border.svg) no-repeat left top;
    background-position-y: 5px;
    @include text("default");
    margin-top: 24px;
    padding-left: 28px;

    @include media-breakpoint-down(md) {
        margin-top: 16px;
    }
}

// .rich-text__img--scale-down {
//     width: unset !important;
//     max-width: 100%;
//     height: unset;
// }

body.dark-theme .rich-text-container {
    &__body {
        *:not(a) {
           color: colors("primary", 100);
        }

        a {
            color: colors("white");
        }
    }
}

.rich-text-container {
    &__body {
        @include rich-text-body;
    }

    &--body-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--body-text &__body {
        max-width: 700px;
        width: 100%;
    }
}

.mce-content-body {
    @include rich-text-body;
}