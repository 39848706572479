// Headers
@function header-font-size($size, $mobile: false) {
    $size: if($mobile==true, $size + "-mobile", $size);
    @return map-get($header-font-sizes, "h" + $size);
}

@function header-font-weight($size, $mobile: false) {
    $size: if($mobile==true, $size + "-mobile", $size);
    @return map-get($header-font-weights, "h" + $size);
}

@function header-line-height($size, $mobile: false) {
    $size: if($mobile==true, $size + "-mobile", $size);
    @return map-get($header-line-heights, "h" + $size);
}

// Buttons
/*
 * Get the background color for a button type
 * $name: The button type name, such as primary, danger, etc.
 * $state: The state of the button, such as hover, focus, etc.
 */
@function button-background-color($name: "default", $state: "normal") {
    $name: if($state != "normal", $name + "-" + $state, $name);
    @return map-get($button-background-colors, $name);
}
/*
 * Get the border color for a button type
 * $name: The button type name, such as primary, danger, etc.
 * $state: The state of the button, such as hover, focus, etc.
 */
@function button-border-color($name: "default", $state: "normal") {
    $name: if($state != "normal", $name + "-" + $state, $name);
    @return map-get($button-border-colors, $name);
}
/*
 * Get the font color for a button type
 * $name: The button type name, such as primary, danger, etc.
 * $state: The state of the button, such as hover, focus, etc.
 */
@function button-font-color($name: "default", $state: "normal") {
    $name: if($state != "normal", $name + "-" + $state, $name);
    @return map-get($button-font-colors, $name);
}

// Color
/* Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

/* Slightly darken a color
 * @access public
 * @param {Color} $color - color to shade
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}