.keen-slider.kundcase-slider {
    overflow: visible;
}

.kundcase-slider {
    height: 555px; // ??

    @include media-max-width(sm-large) {
        height: 420px;
    }

    // &__header {
    //     display: flex;
    //     align-items: center;
    // }

    // &__title {
    //     > * {
    //         margin-bottom: 0;
    //     }
    // }

    // &__text {
    //     flex-basis: 50%;

    //     @include media-breakpoint-down(sm) {
    //         flex-basis: unset;
    //     }
    // }

    // &__header-buttons {
    //     flex-basis: 50%;
    //     text-align-last: end;
    // }

    // &__arrows {
    //     font-size: 28px; // ??

    //     color: colors("primary", 600);
    //     cursor: pointer;

    //     .icon-arrow_right {
    //         padding-left: $sliding-arrow-length;
    //         &::before {
    //             margin-right: 0;
    //         }
    //     }

    //     .icon-arrow_left {
    //         padding-right: $sliding-arrow-length;
    //         &::before {
    //             margin-right: 0;
    //         }
    //     }

    //     i[disabled] {
    //         color: tint(colors("primary", 600), 50);  
    //     }
    // }

    &__slide {
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 32px;

        @include media-max-width(sm-large) {
            padding: 24px;
        }
    }

    &__title-wrapper {
        margin: -32px;
        padding: 32px;
        
        background: linear-gradient(180deg, rgba(211, 213, 208, 0) 0%, rgba(106, 106, 106, 0.72) 100%);
        
        @include media-max-width(sm-large) {
            margin: -24px;
            padding: 24px;
        }
    }

    // Make sure the slider doesn't expand outside the container
    @each $breakpoint, $container-max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            max-width: $container-max-width;
        }
    }
}