@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freehand&display=swap');
$font-family: 'Manrope', sans-serif;
$display-font-family: 'Freehand', sans-serif;

$default-font-color: colors("primary", 800);

// Text
$text-font-weights: (
  default: 400,
  bold: 700,
);
$text-line-height: 1.6;
$text-font-sizes: (
  default: 1rem,
  medium: 0.88889rem,
  small: 0.77778rem,
  smallest: 0.666rem,
);

/* Headers */
$header-font-weights: (
  h1: 700,
  h2: 700,
  h3: 700,
  h4: 700,
  h5: 700,
  h6: 700
);

$header-line-heights: (
  h1: 1.15,
  h2: 1.15,
  h3: 1.2,
  h4: 1.3,
  h5: 1.3,
  h6: 1.45,
  
  h1-mobile: 1.25,
  h2-mobile: 1.25,
  h3-mobile: 1.3,
  h4-mobile: 1.3,
  h5-mobile: 1.4,
  h6-mobile: 1.45 
);

$header-font-sizes: (
  h1: 3.052rem,
  h2: 2.441rem,
  h3: 1.953rem,
  h4: 1.563rem,
  h5: 1.25rem,
  h6: 1rem,
  
  h1-mobile: 1.802rem,
  h2-mobile: 1.602rem,
  h3-mobile: 1.424rem,
  h4-mobile: 1.266rem,
  h5-mobile: 1.125rem,
  h6-mobile: 1rem
);

@function fontWeight($weight: "default") {
  @return map-get($text-font-weights, $weight);
}
@function fontSize($size: "default") {
  @return map-get($text-font-sizes, $size);
}