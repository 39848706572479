@mixin btn {
    min-width: $button-min-width;
    border-radius: $button-border-radius;
    font-weight: $button-font-weight;
    border: none;
    outline: none;
    line-height: $button-line-height;
    cursor: pointer;

    @include btn-style("default");
    @include btn-size("default");

    @include ease-in-color-shadow(.15s);
}

@mixin btn-style($name) {
    background: button-background-color($name);
    color: button-font-color($name);

    @if ($name == "outline", $name == "white-filled"){
        border: 1px solid button-border-color($name);
        box-sizing: border-box;
        box-shadow: none;
    }@else{
        box-shadow: 0 0 0 2pt button-background-color($name);
    }

    &:hover {
        background: button-background-color($name, "hover");
        color: button-font-color($name, "hover") !important;
        box-shadow: none;
    }

    &:focus {
        background: button-background-color($name, "focus");
        color: button-font-color($name, "focus");
        box-shadow: 0px 0px 0px 4px button-border-color($name, "focus");
    }
}

@mixin btn-size($size) {
    padding: map-get($button-paddings, $size);
    font-size: map-get($button-font-sizes, $size);
}