.mega-menu {
    min-height: 380px;
    background-color: colors("primary", 800);
    display: flex;
    @include media-breakpoint-down(md-large) {
        flex-direction: column;
    }
    
    &__cards {
        // justify-self: flex-end;
        // margin-left: auto;
        background-color: colors("primary", 700);
        padding: 32px;
        max-width: 336px;
        @include media-breakpoint-down(md-large) {
            max-width: unset;
        }

        .card {
            padding-bottom: 24px;
            margin-bottom: 0;

            &:first-child {
                margin-bottom: 24px;
            }

            &__body {
                padding: 24px;
                padding-bottom: 0;
                padding-top: 24px !important;
            }

            &__title {
                @include text("medium");
            }

            &__read-more {
                margin-left: 24px;
                margin-top: 8px;
            }
        }
    }

    &__sections {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 56px 64px;
        flex-grow: 1;
    }
    
    &__section {
        display: flex;
        flex-direction: column;
    }

    &__section-link {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include text("medium");
        color: white;

        margin-bottom: 8px;
        height: 30px;
        color: white !important;

        &--has-children {
            @include text("default");
            margin-bottom: 16px;
            font-weight: fontWeight("bold");
        }
        
        &--has-image {
            margin-bottom: 16px;
        }
    }
    
    &__section--independent &__section-link {
        font-weight: fontWeight("bold");
        margin-bottom: 16px;
    }

    &__image-wrapper {
        display: flex;
        margin-right: 12px;
        width: 40px;
        height: 40px;
        background-color: colors("grey-lighter");
        border-radius: 4px;
        
        img {
            margin: auto;
            max-width: 40px;
            padding: 6px;
        }
    }

    &__close-button {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        font-size: fontSize("large");
        color: white;
    }
}