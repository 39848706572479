// Arrows next to text
.sliding-arrow {
    @include ease-in-out(.3s);

    & > * { // All children of any type
        @include ease-in-out(.3s);
    }

    &:hover {
        > .icon-arrow_right {
            margin-right: -$sliding-arrow-length-in-text;
            margin-left: $sliding-arrow-length-in-text;
        }

        > .icon-arrow_left {
            padding-right: $sliding-arrow-length-in-text * 2;
        }
    }
 }

.icon-arrow_left.sliding-arrow:hover:not([disabled]) {
    padding-right: $sliding-arrow-length * 2;
 }
 .icon-arrow_right.sliding-arrow:hover:not([disabled]) {
    margin-right: -$sliding-arrow-length;
    margin-left: $sliding-arrow-length;
 }