
.player-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.react-player iframe {
  max-width: 100%;
}

.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

@media (min-width: 768px) {
  .react-player iframe {
    max-width: 700px;
  }
}

.mp4-video {
  width: 100%;
}