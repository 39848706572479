.padding-row {
    @each $name, $padding in $row-paddings {
        // Generate top padding classes
        &__top {
            &--#{$name} {
                padding-top: $padding;

                @include media-breakpoint-down(sm-large) {
                    padding-top: map-get($row-paddings-mobile, $name);
                }
            }
        }

        // Generate bottom padding classes
        &__bottom {
            &--#{$name} {
                padding-bottom: $padding;

                @include media-breakpoint-down(sm-large) {
                    padding-bottom: map-get($row-paddings-mobile, $name);
                }
            }
        }
    }
}

.row-width {
    @each $name, $width in $row-widths {
        &--#{$name} {
            max-width: $width;
            width: 100%;
        }
    }
}

.cell-padding {
    @each $name, $paddings in $cell-paddings {
        &--#{$name} {
            padding: map-get($paddings, "y") map-get($paddings, "x");

            @include media-breakpoint-down(md) {
                $mobilePaddings: map-get($cell-paddings-mobile, $name);
                padding: map-get($mobilePaddings, "y") map-get($mobilePaddings, "x");
            }
        }
    }
}

[class^="col-"] .column-content {
    & > div {
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// .block-margin {
//     &__default {
//         & > div {
//             margin-bottom: 32px;
//             &:last-child {
//                 margin-bottom: 0;
//             }
//         }
//     }
// }