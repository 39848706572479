.breadcrumb {
    margin-top: 16px;
    margin-bottom: 56px;
    font-size: fontSize("small");

    @include media-breakpoint-down(sm) {
        margin-top: 0;
        margin-bottom: 16px;
    }

    &__link {
        color: colors("primary", 400);
        display: inline-block;

        &--active {
            color: colors("primary", 500);
            font-weight: fontWeight("bold");
        }
    }

    &__divider {
        color: colors("grey-light");
        margin: 0 8px;
    }
}

body.dark-theme .breadcrumb-container {
    // margin-top: -1px; // to hide the small white line that appear on some screen sizes
    background-color: colors("primary", 800);
}