@mixin icon {
    &::before {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: never;
       
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        /* opacity: .8; */
       
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
       
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
       
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .2em;
       
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
       
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
       
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }
}

@mixin icon-after {
    &::after {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: never;
       
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        /* opacity: .8; */
       
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
       
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
       
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .2em;
       
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
       
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
       
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }
}

@mixin icon-phone{ &::before { content: '\e800'; } } /* '' */
@mixin icon-menu{ &::before { content: '\e801'; } } /* '' */
@mixin icon-arrow_left{ &::before { content: '\e802'; } } /* '' */
@mixin icon-arrow_right{ &::before { content: '\e803'; } } /* '' */
@mixin icon-check_circle{ &::before { content: '\e804'; } } /* '' */
@mixin icon-email{ &::before { content: '\e805'; } } /* '' */
@mixin icon-check{ &::before { content: '\e806'; } } /* '' */
@mixin icon-emoji_emotions{ &::before { content: '\e807'; } } /* '' */
@mixin icon-location{ &::before { content: '\e808'; } } /* '' */
@mixin icon-chevron_left{ &::before { content: '\e809'; } } /* '' */
@mixin icon-chevron_right{ &::before { content: '\e80a'; } } /* '' */
@mixin icon-chevron_up{ &::before { content: '\e80b'; } } /* '' */
@mixin icon-chevron_down{ &::before { content: '\e80c'; } } /* '' */
@mixin icon-play_circle{ &::before { content: '\e80d'; } } /* '' */
@mixin icon-cloud{ &::before { content: '\e80e'; } } /* '' */
@mixin icon-globe{ &::before { content: '\e80f'; } } /* '' */
@mixin icon-star{ &::before { content: '\e810'; } } /* '' */
@mixin icon-add_to_cart{ &::before { content: '\e811'; } } /* '' */
@mixin icon-build{ &::before { content: '\e812'; } } /* '' */
@mixin icon-device_hub{ &::before { content: '\e813'; } } /* '' */
@mixin icon-verified{ &::before { content: '\e814'; } } /* '' */
@mixin icon-fullscreen{ &::before { content: '\e815'; } } /* '' */
@mixin icon-zoom_in{ &::before { content: '\e816'; } } /* '' */
@mixin icon-zoom_out{ &::before { content: '\e817'; } } /* '' */
@mixin icon-add{ &::before { content: '\e818'; } } /* '' */
@mixin icon-remove{ &::before { content: '\e819'; } } /* '' */
@mixin icon-backup{ &::before { content: '\e81a'; } } /* '' */
@mixin icon-warning{ &::before { content: '\e81b'; } } /* '' */
@mixin icon-close{ &::before { content: '\e81c'; } } /* '' */
@mixin icon-warning-filled{ &::before { content: '\e81d'; } } /* '' */
@mixin icon-business_center{ &::before { content: '\e81e'; } } /* '' */
@mixin icon-facebook{ &::before { content: '\f09a'; } } /* '' */
@mixin icon-linkedin{ &::before { content: '\f0e1'; } } /* '' */
@mixin icon-cloud-upload{ &::before { content: '\f0ee'; } } /* '' */
@mixin icon-youtube{ &::before { content: '\f16a'; } } /* '' */
@mixin icon-instagram{ &::before { content: '\f16d'; } } /* '' */


@mixin icon-after-phone{ &::after { content: '\e800'; } } /* '' */
@mixin icon-after-menu{ &::after { content: '\e801'; } } /* '' */
@mixin icon-after-arrow_left{ &::after { content: '\e802'; } } /* '' */
@mixin icon-after-arrow_right{ &::after { content: '\e803'; } } /* '' */
@mixin icon-after-check_circle{ &::after { content: '\e804'; } } /* '' */
@mixin icon-after-email{ &::after { content: '\e805'; } } /* '' */
@mixin icon-after-check{ &::after { content: '\e806'; } } /* '' */
@mixin icon-after-emoji_emotions{ &::after { content: '\e807'; } } /* '' */
@mixin icon-after-location{ &::after { content: '\e808'; } } /* '' */
@mixin icon-after-chevron_left{ &::after { content: '\e809'; } } /* '' */
@mixin icon-after-chevron_right{ &::after { content: '\e80a'; } } /* '' */
@mixin icon-after-chevron_up{ &::after { content: '\e80b'; } } /* '' */
@mixin icon-after-chevron_down{ &::after { content: '\e80c'; } } /* '' */
@mixin icon-after-play_circle{ &::after { content: '\e80d'; } } /* '' */
@mixin icon-after-cloud{ &::after { content: '\e80e'; } } /* '' */
@mixin icon-after-globe{ &::after { content: '\e80f'; } } /* '' */
@mixin icon-after-star{ &::after { content: '\e810'; } } /* '' */
@mixin icon-after-add_to_cart{ &::after { content: '\e811'; } } /* '' */
@mixin icon-after-build{ &::after { content: '\e812'; } } /* '' */
@mixin icon-after-device_hub{ &::after { content: '\e813'; } } /* '' */
@mixin icon-after-verified{ &::after { content: '\e814'; } } /* '' */
@mixin icon-after-fullscreen{ &::after { content: '\e815'; } } /* '' */
@mixin icon-after-zoom_in{ &::after { content: '\e816'; } } /* '' */
@mixin icon-after-zoom_out{ &::after { content: '\e817'; } } /* '' */
@mixin icon-after-add{ &::after { content: '\e818'; } } /* '' */
@mixin icon-after-remove{ &::after { content: '\e819'; } } /* '' */
@mixin icon-after-backup{ &::after { content: '\e81a'; } } /* '' */
@mixin icon-after-warning{ &::after { content: '\e81b'; } } /* '' */
@mixin icon-after-close{ &::after { content: '\e81c'; } } /* '' */
@mixin icon-after-warning-filled{ &::after { content: '\e81d'; } } /* '' */
@mixin icon-after-business_center{ &::after { content: '\e81e'; } } /* '' */
@mixin icon-after-facebook{ &::after { content: '\f09a'; } } /* '' */
@mixin icon-after-linkedin{ &::after { content: '\f0e1'; } } /* '' */
@mixin icon-after-cloud-upload{ &::after { content: '\f0ee'; } } /* '' */
@mixin icon-after-youtube{ &::after { content: '\f16a'; } } /* '' */
@mixin icon-after-instagram{ &::after { content: '\f16d'; } } /* '' */