$navbar-background-color: white;
$navbar-background-color-dark: #022D2E;

$navbar-link-text-decoration: none;
$navbar-link-text-decoration-hover: none;
$navbar-link-text-decoration-focus: none;
$navbar-link-text-decoration-active: none;
$navbar-link-text-decoration-visisted: none;

$navbar-link-color-dark: white;
$navbar-link-color-dark-hover: darkgray;
$navbar-link-color-dark-focus: gray;
$navbar-link-color-dark-active: #022D2E;
$navbar-link-color-dark-visited: #022D2E;

$navbar-link-color: colors("primary", 800);
$navbar-link-color-hover: #454b4b;
$navbar-link-color-focus: #022D2E;
$navbar-link-color-active: #022D2E;
$navbar-link-color-visited: #022D2E;

$navbar-link-font-size: rem(16px);

$navbar-link-font-weight: map-get($text-font-weights, "bold");

$transition-collapse: height .35s ease !default;