@include media-breakpoint-down(sm) {
    // .container,
    // .container-fluid {
    //     // TODO Remove !important ;)
    //     padding-left: 16px !important;
    //     padding-right: 16px !important;
    // }

    :not(.container-fluid) .row:not(.no-gutters) {
        margin-right: 0 - $grid-gutter-width / 2;
        margin-left: 0 - $grid-gutter-width / 2;
    }
    // :not(.container-fluid) .row:not(.no-gutters) > .col,
    // :not(.container-fluid) .row:not(.no-gutters) > [class*="col-"] {
    //     padding-right: $grid-gutter-width / 4;
    //     padding-left: $grid-gutter-width / 4;
    // }
}