html,
body {
    margin: 0;
}

body {
    padding-top: 78px;
    overflow-x: hidden;

    @include media-max-width(md-large) {
        padding-top: 72px;
    }
}
main.has-overlay {
    &:after {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
    }
}

body.prevent-scroll {
    overflow: hidden;
    padding-right: 15px;
}

body.prevent-scroll-mobile {
    position: static;
    overflow-y: scroll;
}

// div[id^='react_'] {
//     display: flex;
//     flex-direction: column;
//     flex-grow: 1;
// }

body.dark-theme {
    background-color: colors("primary", 800);

    h1,
    h2,
    h3,
    h4,
    h5:not(.card__title),
    h6,
    p,
    li {
        color: colors("white");
    }

    .article__author__name {
        color: colors("primary");
    }

    footer {
        background-color: white;
    }
}

.umb-macro-holder ins {
    text-decoration: none;
}

.row-wrapper {
    overflow-x: hidden;

    .container {
        opacity: 0;
        transform: translate3d(0, 3.13rem, 0);
        transition: 0.4s ease-in-out;
        transition-property: opacity, transform;
    }

    &--visible {
        .container {
            opacity: 1;
            transform: translateZ(0);
        }
    }

    &--visible-no-animation {
        .container {
            transition: none;
            opacity: 1;
            transform: translateZ(0);
        }
    }
}

.row-wrapper.row-wrapper--curve-top,
.row-wrapper.row-wrapper--curve-bottom {
    overflow: hidden;
}

body.dark-theme .row-wrapper {
    background-color: white;
}
.background-container {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.row-wrapper.row-wrapper--curve-bottom .background-container {
    margin-bottom: 50px;
    overflow: visible;
}
.row-wrapper.row-wrapper--curve-top {
    overflow: visible;
}

body.dark-theme .row-wrapper .background-container {
    background-color: colors("primary", 800);
}

.row-wrapper .background-container--primary-dark {
    a,
    a:visited {
        color: colors("white");
    }
}
