// If html has "data-scroll" attribute, but not equal to 0
// If no html[data-scroll] check, the style is applied on page load, but then removed when javascript has been loaded and appends the attribute
html[data-scroll]:not([data-scroll="0"]) {
    header {
        @include box-shadow-primary;
    }
}

.header {
    position: fixed;
    top: 0;
    z-index: 999;
    height: auto;
    width: 100%;
    transition: box-shadow 0.35s;
}

body.prevent-scroll .navbar.navbar--desktop {
    padding-right: 15px;
}

.navbar {
    display: flex;
    align-items: center;

    .container {
        display: flex;
        align-items: center;
    }
    
    z-index: 1000;

    &--desktop {
        justify-content: center;
        background-color: $navbar-background-color;
    }

    &--desktop &__cta-button {
        &:hover {
            color: #FFF !important;
        }
    }

    &--mobile {
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: flex-start;

        padding: 12px $grid-gutter-width/2;

        height: 100%;
        
        background-color: $navbar-background-color;
    }

    &--mobile .hamburger {
        padding: 0;
    }

    &--desktop &__logo {
        margin-right: auto;
        display: flex;

        img {
            width: 140px;
            max-height: 50px;
        }
    }

    &--mobile &__logo {
        img {
            width: 100px;
            max-height: 40px;
        }
    }

    &--desktop &__item {
        padding: 32px;
        color: colors("primary", 800);
        font-weight: fontWeight("bold");
        font-size: fontSize("medium");
    }

    &--desktop &__item &__item-text {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 2px;
            top: 22px;
            bottom: 0;
            left: 0;
            margin: auto 0;
            background-color: colors("primary");
            visibility: hidden;
            transition: all 0.3s ease-in-out 0s;
        }
    }
    &--desktop &__item.is-active &__item-text::before {
        visibility: visible;
        width: 100%;
    }

    &--desktop &__items {
        display: inline-flex;
        margin-right: auto;

        a {
            padding: 25px 16px;
            color: colors("primary", 800);
            font-weight: fontWeight("bold");
            cursor: pointer;
        }
    }

    &__toggle-btn:focus {
        outline: none;
    }

    &--expanded &__toggle-btn {
        color: white;
    }

    &--expanded &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        width: 100%;
        cursor: pointer;

        color: white;
        border-bottom: 1px solid colors("primary", 700);

        &:last-child {
            margin-bottom: 24px;
        }
    }

    &--expanded {
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: colors("primary", 800);
    }

    &--mobile &__items-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }

    &--expanded &__items-wrapper {
        margin-top: 12px;
    }

    &--mobile &__items {
        overflow-y: auto;
        padding-bottom: 100px;
    }

    &--mobile &__cta-button-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 16px;
        padding-top: 32px;
        z-index: 1000;
                
        background: -moz-linear-gradient(180deg,  rgba(2,45,46,0) 0%, colors("primary", 800) 44%);
        background: -webkit-linear-gradient(180deg,  rgba(2,45,46,0) 0%, colors("primary", 800) 44%);
        background: linear-gradient(180deg,  rgba(2,45,46,0) 0%, colors("primary", 800) 44%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29022d2e', endColorstr='#022d2e',GradientType=0 );
        
    }

    &--mobile &__cta-button {
        width: 100%;
        text-align: center;
    }

    // Make logo white when expanded in mobile
    &--expanded &__logo img {
        /* Ch 23+, Saf 6.0+, BB 10.0+ */
        -webkit-filter: hue-rotate(240deg) saturate(100) grayscale(100%);
        /* FF 35+ */
        filter: hue-rotate(240deg) saturate(100) grayscale(100%);
    }

    &--mobile &__item-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include header(5);
        width: 100%;
        padding: 16px;

        i {
            transition: all 0.1s ease 0s;
        }

        &--main-link {
            @include header(3);
            color: white;
            a {
                color: white;
            }
        }

        color: white;
        a {
            color: white;
        }
    }

    &--mobile &__item-children {
        padding: 0 16px;
        width: 100%;
        
        &--child {
            @include text("default");
            margin-bottom: 12px;

            a {
                color: white;
                transition: color 0.3s;
            }

            a:hover {
                color: #B3B9B9; // colors("grey") funkar inte tydligen
            }
        }

        &--child-wrapper {
            display: flex;
            justify-content: space-between;
        }

        &--child2 {
            display: block;
            margin-left: 16px;
            margin-top: 12px;
        }
    }

    &__child2-toggle {
        // padding-right: 12px;
        display: flex;
        flex-grow: 1;
        align-self: center;
        justify-content: flex-end;
        margin-right: 2px;
        &:before {
            transition: all 0.15s ease-in-out 0s;
            font-size: 20px;
        }

        &--expanded {
            &:before{
                transform: rotate(-180deg);
            }
        }
    }
    &__menu-objects{
        display: flex;
        position: relative;
        justify-content: center;
    }
    &__menu-container{
        position: absolute;
        opacity: 0;
        pointer-events: none;
        top: 20px;
        transition: top 0.2s ease-in-out;

        &--active{
            pointer-events: auto;
            opacity: 1;
            top: 0;
        }
    }
}

body.dark-theme .navbar {
    &--mobile,
    &--desktop {
        background-color: colors("primary", 800);
    }

    &__logo {
        -webkit-filter: hue-rotate(240deg) saturate(100) grayscale(100%);
        filter: hue-rotate(240deg) saturate(100) grayscale(100%);
    }

    &__item-text {
        color: white;
    }

    .hamburger-inner, 
    .hamburger-inner::before, 
    .hamburger-inner::after {
        background-color: white;
    }
}

body.dark-theme .navbar--desktop .navbar__cta-button {
    box-shadow: none;
    color: white;
    border-color: white;
    &:hover {
        color: black !important;
        background: white;
    }
}