.image-gallery {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    color: colors("white");

    i {
        cursor: pointer;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 8px;
    }
    
    &__body {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    &__image-container {
        width: 100%;
    }
}