.comparison-table {
    display: flex;
    flex-direction: column;
    background-color: colors("white");
    padding: 0 32px;
    padding-top: 70px;
    padding-bottom: 40px;
    
    @include box-shadow-primary-large;

    &__header {
        display: flex;
        height: 140px;
    }

    &__header-column {
        display: flex;
        align-self: flex-start;
        justify-content: center;
        width: 100%;
        margin-right: 32px;

        &:first-child {
            justify-content: flex-start;
            align-self: flex-end;
            margin-bottom: 32px;
            font-weight: bold;
        }

        &:last-child {
            margin-right: 0;
        }

        h5 {
            padding: 0;
            margin: 0;
            color: colors("primary", 800) !important;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        
    }

    &__row-data {
        display: flex;
        align-self: flex-end;
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid colors("grey-light");
        margin-right: 32px;
        padding-bottom: 16px;

        &:first-child {
            justify-content: flex-start;
            font-size: fontSize("medium");
        }

        &:last-child {
            margin-right: 0;
        }
    }
    
    &__rows--buttons &__row-data {
        margin-top: 32px;
        border-bottom: none;
        padding-bottom: 0;
        align-self: flex-start;

        .btn {
            width: 100%;
            text-align: center;
        }
    }

    &__row-data-text {
        color: colors("primary", 800);
        font-size: fontSize(medium);
        line-height: 26px;
    }

    &__section {
        margin-top: 40px;
        &--first {
            margin-top: 0;
        }
    }

    &__section-title h5 {
        padding: 0;
        margin: 0;
        color: colors("primary", 800) !important;
        margin-bottom: 32px;
    }

    @include media-breakpoint-down(sm-large) {
        margin-left: -$grid-gutter-width/2;
        margin-right: -$grid-gutter-width/2;
        padding: 0;

        &__row {
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 0;

            &:first-child {
                width: 100%;
            }
        }

        &__row-data {
            width: unset;
            margin-right: 0;
            padding: 10px 0;
            border: 1px solid colors("grey-light");
            border-right: none;
            align-items: center;
            flex-grow: 1;
            justify-content: center;

            &:last-child {
                border-right: 1px solid colors("grey-light");
            }

            &:first-child {
                width: 100%;
                border-bottom: none;
                border-top: none;
                border-right: 1px solid colors("grey-light");
            }

            &:first-child,
            &:nth-child(1) {
                justify-content: center;
            }
        }

        &__header {
            margin-left: 0;
            height: unset;
        }

        &__header-column {
            flex-direction: column;
            align-items: center;
            margin-right: 0;
            padding: 24px 0;
            border-right: 1px solid colors("grey-light");

            &:last-child {
                border-right: none
            }

            h5 {
                margin: 0;
            }
        }

        &__header-image {
            @include image-grey-box(72px);
        }

        &__header-title {
            font-weight: bold;
            border-bottom: none;
            text-align: center;
        }

        &__section {
            margin-top: 0;
            margin-bottom: 24px;
        }

        &__section-title {
            background-color: colors("grey-lighter");
            padding-top: 8px;
            padding-bottom: 8px;
            text-align: center;
            font-weight: bold;
            margin: 0;

            h5 {
                margin: 0;
                line-height: 25px;
            }
        }

        &__rows--buttons &__row-data {
            width: 100%;
            margin-bottom: 8px;
            border: none;
            background-color: colors("grey-lighter");
            padding: 16px;
            margin-top: 8px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .btn {
                width: 100%;
                min-height: 72px;
                line-height: 26px;
                padding: 24px 26px;
            }
        }

        &__button-image {
            @include image-grey-box(72px);
            background-color: colors("white");
            margin-right: 16px;
        }

        &__rows--buttons &__row-data--empty {
            display: none;
        }
    }

    @include media-breakpoint-down("sm") {
        &__header-image {
            @include image-grey-box(48px);
        }

        &__rows--buttons &__row-data {
            .btn {
                min-height: 48px;
                padding: 12px 20px;
            }
        }

        &__button-image {
            @include image-grey-box(48px);
            background-color: colors("white");
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        object-fit: none;
    }
}