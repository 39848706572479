.staff-cta {
    display: flex;

    background-color: colors("primary", 100);
    padding: 40px;

    &__body {
        flex: 1;
    }

    &__caption {
        @include header(6);
        color: colors("primary", 800) !important;
    }

    &__staff-info {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
    }

    &__name {
        margin-right: 32px;
        color: colors("primary", 800) !important;
        font-weight: fontWeight("bold");
        font-size: fontSize("large");
        
        &--title {
            font-size: fontSize("medium");
            color: colors("grey-dark");
            font-weight: fontWeight("default");
        }
    }

    &__contact > a {
        display: block;
        font-weight: fontWeight("bold");
        font-size: fontSize("small");
        &:first-child {
            margin-bottom: 8px;
        }
    }

    &__image-wrapper img {
        object-fit: cover;
        width: 88px;
        height: 88px;
        border-radius: 100%;
        margin-right: 32px;
    }

    @include media-breakpoint-down("sm") {
        flex-direction: column;
        text-align: center;
        padding: 24px;
        padding-bottom: 32px;

        &__image-wrapper img {
            width: 72px;
            height: 72px;
            margin-right: 0;
        }

        &__caption {
            margin-top: 16px;
            line-height: 130%;
        }

        &__staff-info {
            flex-direction: column;
        }

        &__name {
            margin-right: 0;
            margin-bottom: 8px;

            &--title {
                margin-top: 4px;
            }
        }
    }
}