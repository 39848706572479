.faq-card {
    padding: 24px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    cursor: pointer;

    border-left: 2px solid colors("secondary");

    @include transition(0.2s);

    &:hover &__icon i.icon-arrow_right:before {
        margin-right: -4px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {       
        @include header(6);
        margin: 0;
        font-weight: fontWeight("bold");
        color: colors("primary", 800);
    }

    &__icon {
        color: colors("secondary");

        i:before {
            font-size: 24px;
        }

        i.icon-arrow_right:before {
            @include transition(0.2s);
        }
    }

    &__text {
        @include text(medium);
        margin: 0;
        margin-top: 16px;
    }
    
    &__link {
        display: block;
        margin-top: 16px;
    }
}

.faq-card-container {
    margin-top: 16px;
    &:first-child {
        margin-top: 0;
    }
}