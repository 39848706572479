﻿* {
    font-family: $font-family;
}

html, body {
    font-size: 112.5%; // 18px
    @include media-breakpoint-down(md) {
        font-size: 100%; // 16px
    }
}

p {
    @include text("default");
}

.text-bold {
    font-weight: map-get($text-font-weights, "bold");
}

.text-dark {
    color: colors("primary", 800);
}

.text-light {
    color: white;
}

// Generate .text-size classes, such as text-medium, text-small, etc.
@each $text-size-name, $text-size in $text-font-sizes {
    .text-#{$text-size-name} {
        @include text($text-size-name)
    }
}

// Headers
h1 {
    @include header(1);
}
h2 {
    @include header(2);
}
h3 {
    @include header(3);
}
h4 {
    @include header(4);
}
h5 {
    @include header(5);
}
h6 {
    @include header(6);
}

.text-center {
    text-align: center;
}

a {
    color: colors("primary");
    text-decoration: none;

    &:visited {
        color: colors("primary");
    }
}

a.arrow-left {
    @include icon;
    @include icon-arrow_left;

    &::after {
        @include transition(0.2s);
    }
    
    &:hover {
        &::after {
            margin-left: -0.5em;
        }
    }
}

a.arrow-right {
    @include icon-after;
    @include icon-after-arrow_right;

    &::after {
        @include transition(0.2s);
        line-height: inherit;
        font-size: inherit;
        margin-left: 8px;
    }
    
    &:hover {
        &::after {
            margin-left: 12px;
        }
    }
}

.unset-color {
    color: unset !important;
}

ol {
    margin: 0;
    padding: 0;
    padding-bottom: 6px;
    padding-left: 12px;
    list-style-type: none;
    counter-reset: li-counter;

    li {
        position: relative;
        @include text(default);
        margin-top: 0;
        padding-left: 30px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;

            @include header(6);
            margin: 0;

            top: 0;
            left: -12px;
            width: 32px;
            height: 32px;

            text-align: center;
            color: colors("primary");
            border-radius: 100%;
            background-color: colors("primary", 200);

            content: counter(li-counter);
            counter-increment: li-counter;
        }
    }

    li ol {
        margin-top: 24px;
    }
}

.ingress {
    font-size: 24px;
    line-height: 38px;

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 29px;
    }
}