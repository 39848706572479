body.prevent-scroll .fullscreen-menu {
    &__close-button {
        margin-right: 15px;
    }
}

.fullscreen-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: colors("primary", 800);
    z-index: 1001;
    
    &__content {
        height: 100%;
        background: linear-gradient(180deg, colors("primary", 800) 60%, rgba(0,0,0,0.1) 100%);
    }

    &__sections-wrapper {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 104px;
    }

    &__section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &--first {
            display: flex;
            flex-direction: column;
            place-content: stretch flex-start;
            flex: 1 1 0%;
            width: 0px;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        display: flex;

        width: 100%;
        align-items: center;
        justify-content: space-between;

        padding: 16px 104px;
        background-color: colors("primary", 700);
    }

    &__contact {
        & > a {
            color: white;

            &:hover {
                color: #B3B9B9;
            }

            &:last-child {
                margin-left: 34px;
            }
        }
    }

    &__social-media .social-media-bubble {
        display: inline-flex;
        border-radius: 100px;
        padding: 18px;
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        margin-left: 16px;

        &:first-child {
            margin-left: 0;
        }
    }

    &__close-button {
        position: absolute;
        right: 32px;
        top: 32px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-thumb {
    background: colors("primary", 700);
    border-radius: 4px;
    }
    ::-webkit-scrollbar-track{
    background: colors("primary", 600);
    border-radius: 4px;
    box-shadow: inset 7px 10px 12px colors("primary", 600);
    }

    .depth-1 {
        font-size: 22px;
        font-weight: fontWeight("bold");
        color: white;
    }

    .depth-2 {
        @include text("default");
        color: white;
        margin-top: 16px;
        padding-left: 4px;
    }

    .depth-3 {
        @include text("medium");
        color: white;
        padding-left: 16px;
        margin-top: 16px;
    }

    .depth-4 {
        @include text("medium");
        color: white;
        padding-left: 24px;
        margin-top: 16px;
    }

    .has-children {
        font-weight: fontWeight("bold");
    }

    a {
        &:hover {
            color: #B3B9B9;
        }
    }
}

.fullscreen-menu-container{
    opacity: 0;
    pointer-events: none;
    &--active{
        opacity: 1;
        pointer-events: auto;
    }
}