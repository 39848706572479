.article {
    .container {
        padding: 0;
    }
    
    &__header h1 {
        margin-top: 24px;
        margin-bottom: 0;

        @include media-breakpoint-down(sm-large) {
            line-height: 36px;
        }
    }

    &__header,
    &__body {
        position: relative;
        left: 0;
        right: 0;
        width: 100%;
    }

    &__header-container {
        display: flex;
        justify-content: center;
    }

    &__header {
        max-width: 700px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__main-image-container {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 40px 0;
        
        @include media-breakpoint-down(md) {
            margin: 32px 0;
            margin-left: -$grid-gutter-width/2;
            margin-right: -$grid-gutter-width/2;
        }
    }

    &__main-image {
        width: 100%;
    }

    &__tag {
        margin-right: $grid-gutter-width/2;
        @include card-tag;

        // Generate .article__tag--<color name> classes, such as .article__tag--primary, .article__tag--secondary, .article__tag--success, etc.
        @each $color-name, $colorLevels in $colors {
            @if type-of($colorLevels) == "map" {
                &--#{$color-name} {
                    background-color: map-get($colorLevels, 200);
                    color: map-get($colorLevels, 600);
                }
            }
        }
    }

    &__date {
        font-size: fontSize("small");
        color: colors("primary", 600);
    }

    &__author {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 0;
        margin-top: $grid-gutter-width;

        &__info {
            margin-left: 8px;
        }

        &__name {
            font-weight: fontWeight("bold");
            color: colors("primary", 800);
            @include text("default");
            line-height: 26px;
        }

        &__title {
            color: colors("grey-dark");
            @include text("medium");
            line-height: 22px;
        }

        &__avatar {
            object-fit: cover;
            width: 56px;
            height: 56px;
            border-radius: 100%;

            &--initials {
                text-align: center;
                justify-content: center;
                background: colors("primary", 200);
                color: colors("primary", 600);
                height: unset;
                line-height: 56px;
            }
        }
    }

    &--no-image &__header-container {
        margin-bottom: 32px;
    }

    .staff-cta,
    .staff-cta-container {
        max-width: 700px;
        width: 100%;
        margin-top: 48px;

        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }
    }

    .umb-macro-holder {
        margin: 32px 0;
    }
}