.exploded-images {
    &__1,
    &__2 img,
    &__3 img,
    &__4 img {
        width: 100%;
    }

    &__1 {
        max-height: 700px;
    }

    &__3 {
        margin-top: 64px;
    }

    &__4 {
        margin-top: 32px;
    }

    &__3-4 {
        margin-right: -40px !important;
        margin-left: -16px !important;

        .col-6 {
            &:first-child {
                justify-content: center;
                margin-right: -24px;
                padding-left: 0;
                padding-right: 0;
                z-index: 1;
            }

            &:last-child {
                padding-left: 0;
                padding-right: 0;
            }
        }

        img {
            width: 100%;
        }
    }

    &__text-section {
        margin-top: 32px;
        
        @include media-breakpoint-only(md) {
            margin-bottom: 32px;
        }
    }

    &__text-section .rich-text-container {
        padding: 64px 56px;
        
        @include media-breakpoint-down(md) {
            padding: 0;
        }

        &__body {
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }

    .image-text-left-right__image-wrapper {
        padding-left: 0 !important;
        margin-left: -16px !important;
        margin-right: -16px !important;
    }
}