@charset "UTF-8";

// Vanilla css
@import "./css/fontello-ie7.css";
@import "./css/fontello.css";

// Bootstrap breakpoint mixins
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";

// Keen-slider
@import "../../node_modules/keen-slider/keen-slider.min.css";

// Abstracts (configuration and helpers)
@import 
    "abstracts/math",
    "abstracts/animations",
    "variables/",
    "abstracts/functions";

// Mixins
@import
    'mixins/';

// Hamburgers
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers";

// Bootstrap
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities/float";

@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/nav";

@import "components/screen-reader";

// Components
@import
    "components/";

// Plugins
@import
    "./plugins/";