$button-border-radius: 4px;
$button-min-width: 104px;
$button-font-weight: 700;
$button-line-height: 1.75;

$enabled-buttons: (
    "default",
    "primary",
    "outline",
    "white-filled"
);

/* Button backgroun colors */
$button-background-colors: (
  // Normal state
  default: #D9DCDC,
  primary: colors("primary"),
  secondary: map-get($colors, "secondary"),
  success: map-get($colors, "success"),
  warning: map-get($colors, "warning"),
  danger: map-get($colors, "danger"),
  outline: rgba(0,0,0,0),
  white-filled: #FFFFFF,

  // Hover state
  default-hover: #F2F4F4,
  primary-hover: #51B5B9,
  secondary-hover: map-get($colors, "secondary"),
  success-hover: map-get($colors, "success"),
  warning-hover: map-get($colors, "warning"),
  danger-hover: map-get($colors, "danger"),
  outline-hover: colors("primary"),
  white-filled-hover: colors("primary"),

  // Focus state
  primary-focus: #51B5B9,
  outline-focus: colors("primary"),
  white-filled-focus: colors("primary"),
);

/* Button border colors */
$button-border-colors: (
  default: #B3B9B9,
  primary: #04686D,
  secondary: map-get($colors, "secondary"),
  success: map-get($colors, "success"),
  warning: map-get($colors, "warning"),
  danger: map-get($colors, "danger"),
  outline: #06959B,
  white-filled: #06959B,

  default-focus: rgba(#B3B9B9, 0.3),
  primary-focus: rgba(colors("primary"), 0.3),
  outline-focus: rgba(colors("primary"), 0.3),
  white-filled-focus: rgba(colors("primary"), 0.3),
);

/* Button font colors */
$button-font-colors: (
  // Normal state
  default: #000000,
  primary: #FFFFFF,
  outline: colors("primary"),
  white-filled: colors("primary"),

  // Hover state
  outline-hover: #FFFFFF,
  white-filled-hover: #FFFFFF,

  // Focus state
  outline-focus: #FFFFFF,
  white-filled-focus: #FFFFFF,
);

$button-paddings: (
  default: 12px 20px,
  large: 16px 24px,
  small: 8px 16px,
);

$button-font-sizes: (
  default: 16px,
  large: 16px,
  small: 14px,
);