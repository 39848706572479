.cta-block {
    display: flex;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 500px;

    @include media-breakpoint-down(md) {
        margin-bottom: -16px;
    }

    &__button {
        align-self: flex-start;
        @include media-breakpoint-only(md) {
            @include btn-size("default");
        }
        @include media-breakpoint-down(sm-large) {
            @include btn-size("small");
        }
    }

    @include media-max-width(md-large) {
        height: 300px; 
    }

    @include media-max-width(sm-large) {
        height: 220px;
    }

    &__title h2 {
        @include media-max-width(sm-large) {
            @include header(4);
        }
    }


    &__container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        height: inherit;
        padding: 64px 72px;

        @include media-breakpoint-down(md-large) {
            padding: 38px;
        }

        @include media-breakpoint-down(sm-large) {
            padding: 20px 24px;
        }
    }
}