.motillo-form {
    max-width: 700px;
    width: 100%;

    &__navigation {
        [class^="col-"] {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__file-upload {
        padding: 48px;
        min-height: 142px;
        width: 100%;
        background-color: colors("primary", 50);
        @include dashed-border;
        @include text-small;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background-color: colors("primary", 100);
        }

        i {
            font-size: 48px;
            margin-bottom: 8px;
            color: colors("primary");
        }
    }

    &__textarea {
        resize: none;
    }

    &__fieldset {
        border: none;
        padding: 0;
        margin-bottom: 16px;
    }

    &__field-tooltip {
        @include text-smallest;
        margin: 2px 0;

        &--as-label {
            @include text("medium");
            font-weight: fontWeight("bold");
            margin: 0;
            margin-bottom: 6px;
        }
    }

    .field-validation-error {
        display: flex;
        align-items: center;
        padding: 8px;
        font-size: 0.666rem;
        line-height: 19px;
        font-weight: 400;
        background-color: rgba(colors("danger-light"), 0.5);
        margin-top: 8px;
        @include icon;
        @include icon-warning-filled;
        &:before {
            color: colors("danger");
            font-size: 14px;
            margin-right: 8px;
        }
    }

    &__field-wrapper {
        position: relative;
    }

    &__field {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__field.shortanswer,
    &__field.longanswer {
        input:not([type="checkbox"]):not([type="radio"]):not([type="file"]),
        textarea {
            width: 100%;
            color: colors("primary", 800);
            caret-color: colors("primary", 800);
            
            border: 1px solid colors(grey);
            padding: 16px;
            outline: none;

            @include text(medium);
            margin: 0;

            @include ease-in-out(0.2s, border);

            &:focus {
                border-color: colors("grey-darker");
            }
        }
    }

    &__field--has-error {
        input/*:not([type="checkbox"]):not([type="radio"]):not([type="file"])*/,
        textarea {
            border-color: colors("danger");

            &:focus {
                border-color: colors("danger");
            }
        }
    }

    &__field.shortanswer:focus-within &__label,
    &__field.longanswer:focus-within &__label,
    &__field.shortanswer &__field-wrapper--has-value &__label,
    &__field.longanswer &__field-wrapper--has-value &__label {
        top: 6px;
        font-size: 10px;
        line-height: 16px;
        color: colors("primary", 800);
    }

    &__field.shortanswer &__label,
    &__field.longanswer &__label {
        position: absolute;
        top: 18px;
        left: 16px;
        
        color: colors("grey-darker");

        @include transition(0.2s);

        @include text-small;
        line-height: 22px;
    }

    &__field.singlechoice {
        label:first-child {
            @include text(medium);
            margin-top: 0;
            font-weight: fontWeight("bold");
        }

        [class^="col-"] {
            flex-direction: row;
        }

        .radiobuttonlist {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 24px;

            label {
                margin-left: 12px;
                word-wrap: break-word;
                @include text-small;
            }

            .radiobutton-container {
                display: flex;
                align-items: center;
                margin-right: 24px;
                padding: 4px 0;

                input {
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;

                    width: 24px;
                    height: 24px;
                    border: 1px solid grey;
                    border-radius: 100%;
                    @include transition(0.2s);

                    &:focus {
                        border-color: colors("primary");
                        outline: none;
                        border-width: 2px;
                    }

                    &:checked {
                        border-color: colors("primary");
                        border-width: 8px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__field.checkbox {
        label {
            @include text-small;
        }
    }

    &__field.multiplechoice,
    &__field.dataconsent {
        label:first-child {
            @include text(medium);
            margin-top: 0;
            font-weight: fontWeight("bold");
        }

        .checkbox-container {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            label {
                @include text-small;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__field.dataconsent &__field-wrapper {
        display: flex;
        align-items: center;

        label {
            @include text-small;
        }
    }

    input.motillo-form__checkbox {
        appearance: none;
        border: 1px solid grey;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        @include transition(0.2s);

        &:focus {
            outline: none;
            border-color: colors("primary");
            border-width: 2px;
        }

        &:before {
            content: '';
            margin: 0;
            font-size: 24px;
            color: white;
        }

        &:checked {
            background-color: colors("primary");
            border: none;
            &:before {
                content: '\e806';
            }
        }
    }

    &__field.checkbox &__field-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__field.checkbox &__field-wrapper &__field-tooltip {
        width: 100%;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}