.featured-partners {
    &__partner {
        margin-top: 40px;
    }

    &__logo {
        float: left;
        position: relative;
        width: 72px;
        height: 72px;
        background-color: colors("grey-lighter");
        border-radius: 4px;

        img {
            display: block;
            
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            width: 48px;
        }
    }

    &__body {
        margin-left: 94px;

        h5 {
            color: colors("white");
        }

        p {
            color: colors("white");
        }
    }
}