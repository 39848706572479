.statistics-block {
    text-align: center;

    &__value-container {
        color: colors("primary", 400);
        font-size: 70px;
        line-height: 70px;
        font-weight: fontWeight("bold");
        margin-bottom: 16px;

        @include media-breakpoint-down(sm-large) {
            font-size: 60px;
            line-height: 60px;
            margin-bottom: 8px;
        }
    }

    &__caption {
        font-size: 24px;
        line-height: 38px;

        @include media-breakpoint-down(sm-large) {
            font-size: 18px;
            line-height: 29px;
        }
    }
}

body.dark-theme .statistics-block {
    &__caption {
        color: colors("white");
    }
}