.keen-slider.kundcase-slider {
    overflow: visible;
}

.slider-wrapper {
    &__header {
        display: flex;
        align-items: center;
    }

    &__title {
        > * {
            margin-bottom: 0;
        }
    }

    &__text {
        flex-basis: 50%;

        @include media-breakpoint-down(sm) {
            flex-basis: unset;
        }
    }

    &__header-buttons {
        flex-basis: 50%;
        text-align-last: end;
    }

    &__arrows {
        font-size: 28px; // ??

        color: colors("primary", 600);
        cursor: pointer;

        .icon-arrow_right {
            padding-left: $sliding-arrow-length;
            &::before {
                margin-right: 0;
            }
        }

        .icon-arrow_left {
            padding-right: $sliding-arrow-length;
            &::before {
                margin-right: 0;
            }
        }

        i[disabled] {
            color: tint(colors("primary", 600), 50);  
        }
    }
}

body.dark-theme .slider {
    &__arrows {
        color: tint(colors("primary", 600), 50);  

        i[disabled] {
            color: colors("primary", 600);
        }
    }
}