.progress-bar {
    height: 6px;
    width: 250px;

    &__container {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        background-color: #B3B9B9;
    }

    &__progress {
        width: 100%;
        height: 100%;
        
        background: colors("primary");
        width: 0;
        border-radius: 2px 0 0 2px;

        &--done {
            border-radius: 2px;
        }
    }
}