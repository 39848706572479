.kundcase-list-view {
    &__item-image-wrapper {
        position: relative;
        img {
            width: 100%;
        }
    }

    &__item-title {
        @include header(4);
        margin-bottom: 0;
        margin-top: 24px;

        @include media-breakpoint-down(sm-large) {
            margin-top: 16px;
        }
    }

    &__item-description {
        @include text(default);
        margin-top: 4px;
        margin-bottom: 8px;
    }

    &__item {
        padding: 0 48px;
        margin-bottom: 104px;

        @include media-breakpoint-up(md) {
            &:last-child,
            &:nth-last-child(2) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            padding: 16px;
            margin-bottom: 0;
        }

        @include media-breakpoint-down(sm-large) {
            padding: 0;
            margin-bottom: 40px;
        }
    }

    &__item-read-more-link {
        @include text(default);
        margin: 0;
        font-weight: fontWeight(bold);
    }
}