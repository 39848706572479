.standalone-card-container {
    height: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;

    &--standalone {
        height: 100%;
    }

    padding-bottom: 32px;

    &--padding {
        padding: 32px;
    }

    border: none;
    border-radius: none;

    @include box-shadow-primary-large;
    background-color: white;

    &__title {
        margin: 0;
    }

    &__read-more {
        @include text(medium);
        margin-top: auto;
        margin-bottom: 0;
        margin-right: auto;
        margin-left: 32px;
        font-weight: bold;

        &::after {
            font-size: 18px !important;
        }
    }

    &__body {
        padding: 32px;
    }

    &__link-card {
        padding-bottom: 0;
    }

    &__link-card--primary {
        border-left: 6px solid colors("secondary");    
    }

    &__link-card &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: unset;
        flex: unset;
        padding: 0;
    }

    &__link-card &__title {
        @include header(4);
        color: colors("primary", 800);
        margin-bottom: 0;
        width: 100%;
        padding: 32px;
        padding-right :0;
        
        white-space: nowrap;
        overflow: hidden;
        
        &:after {
            content: '';
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 88%);
            background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 88%);
            background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 88%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
        }
    }

    &__link-card &__arrow {
        @include header(4);
        color: colors("secondary");
        z-index: 0;
    }

    &__link-card &__link {
        padding: 32px;
        padding-left: 0;
    }

    &__image-wrapper {
        img {
            display: block;
            width: 100%;
            max-height: 250px;
            object-fit: cover;
        }

        &--loading {
            height: 250px;
        }
    }

    &--small-image .card__image-wrapper {
        display: inline-flex;
        align-items: center;
        margin-top: 32px;
        margin-left: 32px;
        background-color: colors("grey-lighter");
        border-radius: 4px;
        padding: 12px;
        width: 72px;
        height: 72px;

        img {
            width: 48px;
        }
    }

    &__text {
        @include text(default);
        margin-top: 8px;
    }

    &--no-image &__body {
        padding-top: 48px;
    }
}

.list-view--masonry .card {
    margin-bottom: 0;
}