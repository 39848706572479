.featured-customers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: visible;

    &--keen-slider {
        overflow: visible;
        align-items: center;
        
        .keen-slider__slide {
            display: flex;
            justify-content: center;

            &:first-child {
                justify-content: flex-start;
            }
        }
    }

    &__customer {
        transition: filter 0.2s ease-in-out;

        @include media-breakpoint-down(md) {
            padding: 0 8px;

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }

        img {
            max-height: 60px;
            max-width: 150px;
            object-fit: contain;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }

        @include media-breakpoint-down(md) {
            // max-width: 120px;
        }
        
        // @include media-breakpoint-down(sm) {
        //     display: inline-block;
        //     &:first-child {
        //         margin-left: 0;
        //     }
        // }
    }
}