@mixin card-tag-position {
    position: absolute;
    top: 16px;
    right: 16px;
}

@mixin card-tag {
    text-decoration: none;
    line-height: 20px;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: fontSize("smallest");
    font-weight: fontWeight("bold");
}