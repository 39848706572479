.card-tag-wrapper {
    pointer-events: none;
    @include card-tag-position;
    display: flex;
    gap: 4px;
}

.card-tag {
    @include card-tag;

    // Generate .card-tag--<color name> classes, such as .card-tag--primary, .card-tag--secondary, .card-tag--success, etc.
    @each $color-name, $colorLevels in $colors {
        @if type-of($colorLevels) == "map" {
            &--#{$color-name} {
                background-color: map-get($colorLevels, 200);
                color: map-get($colorLevels, 600) !important;
            }
        }
    }

    &--recommended {
        right: unset;
        left: -8px;
        border-radius: unset;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            left: 0.5px;
            bottom: -4.80px;
            transform: rotate(45deg);
            border-radius: unset;
            z-index: 0;
            width: 0;
            height: 0;
            border-left: 5.75px solid transparent;
            border-right: 5.75px solid transparent;
            border-bottom: 5.75px solid colors("primary", 400);
        }
    }
}