@function getSmallImageSize($breakpoint) {
    @if $breakpoint == lg {
        @return 148px;
    }

    @if $breakpoint == md-large {
        @return 192px;
    }

    @if $breakpoint == sm-large {
        @return 148px;
    }

    @if $breakpoint == 425px {
        @return 111px;
    }

    @if $breakpoint == sm {
        @return 92px;
    }
}

.overlapping-image {
    // &__text {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     padding: 0 48px;
    //     height: 100%;

    //     @include media-max-width(md) {
    //         padding: 0;
    //     }

    //     & > *:last-child {
    //         margin-bottom: 0;
    //     }
    // }

    &__caption {
        @include text-smallest;
        color: colors("grey-dark");
        text-align: center;
        margin-top: 8px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;

        &--has-small-image {
            .overlapping-image__caption {
                position: absolute;
                margin-top: 0;
            }

            .overlapping-image__image-wrapper {
                padding: 0;
            }

            &--top-left {
                .overlapping-image__small-image {
                    top: 0;
                    left: 0;
                }

                .overlapping-image__image-wrapper {
                    padding: 48px 48px 0;

                    @include media-max-width(md) {
                        padding-right: 16px;
                    }
                }

                .overlapping-image__caption {
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &--top-right {
                .overlapping-image__small-image {
                    top: 0;
                    right: 0;
                }

                .overlapping-image__image-wrapper {
                    padding: 48px 48px 0;

                    @include media-max-width(md) {
                        padding-left: 16px;
                    }
                }

                .overlapping-image__caption {
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &--bottom-left {
                .overlapping-image__small-image {
                    bottom: 0;
                    left: 0;
                }

                .overlapping-image__image-wrapper {
                    padding: 0 48px 48px;

                    @include media-max-width(md) {
                        padding-right: 16px;
                    }
                }

                .overlapping-image__caption {
                    left: 192px + 16px;
                    right: 0;
                    bottom: 16px;
                    margin-left: auto;
                    margin-right: auto;

                    @include media-max-width(lg) {
                        left: getSmallImageSize(lg) + 16px;
                    }
            
                    @include media-max-width(md-large) {
                        left: getSmallImageSize(md-large) + 16px;
                    }
            
                    @include media-max-width(sm-large) {
                        left: getSmallImageSize(sm-large) + 16px;
                    }
            
                    // Custom break point for this
                    @media only screen and (max-width: 425px) {
                        left: getSmallImageSize(425px) + 16px;
                    }
            
                    @include media-max-width(sm) {
                        left: getSmallImageSize(sm) + 16px;
                    }
                }
            }

            &--bottom-right {
                .overlapping-image__small-image {
                    bottom: 0;
                    right: 0;
                }

                .overlapping-image__image-wrapper {
                    padding: 0 48px 48px;

                    @include media-breakpoint-down(md) {
                        padding-left: 16px;
                    }
                }

                .overlapping-image__caption {
                    left: 0;
                    right: 192px + 16px;
                    bottom: 16px;

                    @include media-max-width(lg) {
                        right: getSmallImageSize(lg) + 16px;
                    }
            
                    @include media-max-width(md-large) {
                        right: getSmallImageSize(md-large) + 16px;
                    }
            
                    @include media-max-width(sm-large) {
                        right: getSmallImageSize(sm-large) + 16px;
                    }
            
                    // Custom break point for this
                    @media only screen and (max-width: 425px) {
                        right: getSmallImageSize(425px) + 16px;
                    }
            
                    @include media-max-width(sm) {
                        right: getSmallImageSize(sm) + 16px;
                    }
                }
            }

            &--middle-left {
                .overlapping-image__small-image {
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    left: 0;
                }

                .overlapping-image__image-wrapper {
                    padding: 0 48px 0;

                    @include media-max-width(md) {
                        padding-right: 16px;
                    }
                }
            }
        }
    }

    &__image-wrapper {
        position: relative;
        padding: 0 48px;
        padding-bottom: 48px;

        @include media-max-width(md) {
            padding: 0;
        }
    }

    &__image {
        display: block;
        width: 100%;
        object-fit: cover; 
    }

    &__small-image {
        position: absolute;
        width: 192px;
        height: 192px;
        object-fit: cover;
        padding: 0;

        @include box-shadow-primary-large;

        @include media-max-width(lg) {
            width: 148px;
            height: 148px;
        }

        @include media-max-width(md-large) {
            width: 192px;
            height: 192px;
        }

        @include media-breakpoint-only(md) {
            width: 104px;
            height: 104px;
        }

        @include media-max-width(sm-large) {
            width: 148px;
            height: 148px;
        }

        // Custom break point for this
        @media only screen and (max-width: 425px) {
            width: 111px;
            height: 111px;
        }

        @include media-max-width(sm) {
            width: 92px;
            height: 92px;
        }
    }
}