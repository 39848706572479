.quote {
    position: relative;

    max-width: 700px;
    padding-top: 32px;
    padding-left: 52px;

    @include media-breakpoint-down(sm-large) {
        padding-top: 16px;
        padding-left: 32px;
    }

    &__title {
        position: relative;
        margin: 0;
        z-index: 1
    }

    &__body-text {
        @include text(default);
        margin: 0;
        margin-top: 24px;

        @include media-breakpoint-down(sm-large) {
            margin-top: 16px;
        }
    }

    &__person {
        margin-top: 32px;
        display: flex;
        align-items: center;
        margin-right: auto;

        img {
            width: 56px;
            height: 56px;
            border-radius: 100%;
            margin-right: 8px;
            object-fit: cover;
        }

        @include media-breakpoint-down(sm-large) {
            margin-top: 24px;
        }
    }

    &__person-info {
        display: flex;
        flex-direction: column;
    }

    &__person-name {
        @include text(medium);
        font-weight: bold;
        margin: 0;
    }

    &__person-title {
        @include text(medium);
        margin: 0;
    }

    &__quote-symbol {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        max-width: 64px;
        width: 100%;

        @include media-breakpoint-down(sm-large) {
            max-width: 40px;
        }
    }
}

body.dark-theme .quote {
    color: colors("white");
}