[class^="col-"] {
    display: flex;
    flex-direction: column;
}

[class^="col-md-"] {
    @include media-breakpoint-down(sm-large) {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}