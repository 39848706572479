.text-with-links {
    display: flex;
    flex-direction: column;
    gap: 95px;

    @include media-breakpoint-up(md-large){
        gap: 40px;
        flex-direction: row;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        z-index: 1;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        color: colors("white");
        margin: 0;
        padding-right: 24px;

        @include media-breakpoint-up(md-large){
            padding-right: 0;
        }
    }
    
    &__body-text {
        @include text(large);
        color: colors("white");
        padding-right: 24px;

        @include media-breakpoint-up(md-large){
            padding-right: 0;
            max-width: 698px;
        }

        
        & p {
            margin: 0;
        }
    }

    &__person {
        margin-top: 8px;
        display: flex;
        gap: 16px;
        align-items: center;
    }

    &__person-img {
        width: 72px;
        height: 72px;
        object-fit: cover;
        border-radius: 100%;
        background-color: colors("white");
    }

    &__person-info {
        display: flex;
        flex-direction: column;
    }

    &__person-name {
        @include header(5);
        color: colors("white");
    }

    &__person-title {
        @include text(medium);
        color: colors("white");
    }

    &__links {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 0 16px 0;

        @include media-breakpoint-up(md-large){
            max-width: 446px;
            width: 100%;
            padding: 71px 0;
        }

        @include media-breakpoint-up(xl){
            max-width: 698px;
        }
    }

    &__links-title {
        position: absolute;
        bottom: calc(100% + 16px);
        left: 8px;
        width: calc(100% - 8px);
        font-family: $display-font-family;
        font-size: 44px;
        line-height: 1.25;
        color: colors("secondary", 500);

        @include media-breakpoint-up(md-large){
            bottom: calc(100% - 55px);
            left: 16px;
            width: calc(100% - 16px);
        }
    }

    &__links-title-vector {
        position: absolute;
        pointer-events: none;

        &--desktop {
            opacity: 0;
            right: calc(100% + 16px);
            width: 300px;
            bottom: 4px;

            @include media-breakpoint-up(md-large){
                opacity: 100%;                
            }
        }

        &--mobile {
            opacity: 100%;
            width: 160px;
            right: -8px;
            bottom: 12px;

            @media (min-width: 400px){
                width: 200px;
            }

            @include media-breakpoint-up(md-large){
                opacity: 0;
            }
        }
    }

    &__links-list {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include media-breakpoint-up(md-large){
            gap: 16px;
        }
    }

    &__links-list-item {
        list-style-type: none;
    }

    &__links-list-anchor {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        width: 100%;
        background: linear-gradient(to left, colors("white") 50%, colors("secondary", 500) 50%) right;
        background-size: 200% 100%;
        @include header(5);
        color: colors("primary", 800) !important;
        padding: 16px 16px 16px 12px;
        border-left: 8px solid colors("secondary", 500);
        transition: all 400ms ease;

        &:hover {
            background-position: left;
            color: colors("black") !important;
        }
    }

    &__links-list-image {
        width: 24px;
        pointer-events: none;

        @include media-breakpoint-up(md-large){
            width: 28px;
        }
    }
}