@mixin image-grey-box($size) {
    @if ($size == null) {
        $size: 72px;
    }

    float: left;
    position: relative;
    width: $size;
    height: $size;
    background-color: colors("grey-lighter");
    border-radius: 4px;

    img {
        display: block;
        
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        padding: 8px;
    }
}